let routeParams;
try {
	routeParams = require('../../routeParams_static.json');
} catch (e) {
	if (e.code !== 'MODULE_NOT_FOUND') {
		throw e;
	}
}

const themes = routeParams ? routeParams.themes : {};
const countries = routeParams ? routeParams.countries : [];
const domains = routeParams ? routeParams.domains : {};
const chains = routeParams ? routeParams.chains : [];
const tourOperators = routeParams ? routeParams.tourOperators : [];
const bookingSubPageOptions = `vluchtinformatie|huurauto-boeken|transfers|reisgezelschap`;

const themeOptions = Object.values(themes)
	.map((item) => `${item}.*?`)
	.join('|');

const countryOptions = Object.values(countries).join('|');

const domainOptions = Object.values(domains)
	.map((item) => item)
	.join('|');

const chainsOptions = chains.map((chain) => chain.slug).join('|');

const tourOperatorOptions = tourOperators.map((tourOperator) => tourOperator.slug).join('|');

let baseRoutes = [];
const domainOrTheme = `${domainOptions}|${themeOptions}`;

baseRoutes.push(
	{ source: `/accommodation.xml`, destination: `${process.env.NEXT_PUBLIC_SITEMAP_ENDPOINT_URL}/accommodation.xml` },
	{ source: `/content_pages.xml`, destination: `${process.env.NEXT_PUBLIC_SITEMAP_ENDPOINT_URL}/content_pages.xml` },
	{
		source: `/branded_search_pages.xml`,
		destination: `${process.env.NEXT_PUBLIC_SITEMAP_ENDPOINT_URL}/branded_search_pages.xml`,
	},
	{ source: '/_ah/warmup', destination: '/api/warmup' },
	{ source: '/', destination: '/index' },
	{ source: '/zoeken', destination: '/search' },
	{ source: '/boeking/status/:sessionId', destination: '/booking/status' },
	{ source: '/boeking/storing', destination: '/booking/outage' },
	{ source: '/boeking/:sessionId', destination: '/booking' },
	{
		source: `/:country(${countryOptions})/:region/:city/:accommodation((?!${domainOrTheme})[^/]+)`,
		destination: '/accommodation',
	},
	{
		source: `/:country(${countryOptions})/:region?/:city?/:domains(${domainOptions})`,
		destination: '/search',
	},
	{
		source: `/:country(${countryOptions})/:region?/:city?/:themes(${themeOptions})`,
		destination: '/search',
	},
	/** Needs check because chains can be empty when building Vakanties on release */
	...(chainsOptions.length > 0
		? [
				{
					source: `/:country(${countryOptions})/:region?/:city?/:chain(${chainsOptions})`,
					destination: '/search',
				},
			]
		: []),
	{
		source: `/:country(${countryOptions})/:region?/:city?/:tourOperator(${tourOperatorOptions})`,
		destination: '/search',
	},
	{
		source: `/:country(${countryOptions})/:region?/:city?`,
		destination: '/search',
	},
	{
		source: `/:country(${countryOptions})/:region?/:domains(${domainOptions})`,
		destination: '/search',
	},
	{
		source: `/:country(${countryOptions})/:region?/:themes(${themeOptions})`,
		destination: '/search',
	},
	/** Needs check because chains can be empty when building Vakanties on release */
	...(chainsOptions.length > 0
		? [
				{
					source: `/:country(${countryOptions})/:region?/:chain(${chainsOptions})`,
					destination: '/search',
				},
			]
		: []),
	{
		source: `/:country(${countryOptions})/:region?/:tourOperator(${tourOperatorOptions})`,
		destination: '/search',
	},
	{
		source: `/:country(${countryOptions})/:region`,
		destination: '/search',
	},
	{
		source: `/:country(${countryOptions})/:domains(${domainOptions})?`,
		destination: '/search',
	},
	{
		source: `/:country(${countryOptions})/:themes(${themeOptions})?`,
		destination: '/search',
	},
	/** Needs check because chains can be empty when building Vakanties on release */
	...(chainsOptions.length > 0
		? [
				{
					source: `/:country(${countryOptions})/:chain(${chainsOptions})?`,
					destination: '/search',
				},
			]
		: []),
	{
		source: `/:country(${countryOptions})/:tourOperator(${tourOperatorOptions})?`,
		destination: '/search',
	},
	{
		source: `/:domains(${domainOptions})?`,
		destination: '/search',
	},
	{
		source: `/:themes(${themeOptions})?`,
		destination: '/search',
	},
	/** Needs check because chains can be empty when building Vakanties on release */
	...(chainsOptions.length > 0
		? [
				{
					source: `/:chain(${chainsOptions})?`,
					destination: '/search',
				},
			]
		: []),
	{
		source: `/:tourOperator(${tourOperatorOptions})?`,
		destination: '/search',
	},
	/**
	 * Fix to also allow /mijn-vakanties to be visited without a redirect
	 * Will change to an account overview page when that's needed
	 **/
	{ source: `/${process.env.NEXT_PUBLIC_USER_BASE_PATH}`, destination: '/myVakanties' },
	{ source: `/${process.env.NEXT_PUBLIC_USER_BASE_PATH}/mijn-boekingen`, destination: '/myVakanties' },
	{ source: '/registreren', destination: '/register' },
	{ source: '/wachtwoord-vergeten', destination: '/forgotPassword' },
	{
		source: `/${process.env.NEXT_PUBLIC_USER_BASE_PATH}/boeking/:subPage(${bookingSubPageOptions})/:bookingNumber`,
		destination: '/myVakanties/booking',
	},
	{
		source: `/${process.env.NEXT_PUBLIC_USER_BASE_PATH}/boeking/:bookingNumber/:paymentId?`,
		destination: '/myVakanties/booking',
	},
	{
		source: `/${process.env.NEXT_PUBLIC_USER_BASE_PATH}/profiel/wachtwoord-wijzigen`,
		destination: '/myVakanties/profile/changePassword',
	},
	{
		source: `/${process.env.NEXT_PUBLIC_USER_BASE_PATH}/profiel`,
		destination: '/myVakanties/profile',
	},
	{
		source: `/${process.env.NEXT_PUBLIC_USER_BASE_PATH}/profiel/verwijderen`,
		destination: '/myVakanties/profile/delete',
	},
	{
		source: `/${process.env.NEXT_PUBLIC_USER_BASE_PATH}/contact`,
		destination: '/myVakanties/contact',
	},
	{
		source: '/:slug*',
		destination: '/content/:slug*',
		pathname: '/content/[...slug]',
	},
);

module.exports.baseRoutes = baseRoutes;
module.exports.themes = themes;
module.exports.domains = domains;
module.exports.countries = countries;
